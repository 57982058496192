@import './partials/variables';
@import './partials/mixins';


.page-not-found {
    height: 550px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  margin-top: 2rem;
    background-image: url(./../assets/images/not-found-page-2.jpg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  
    .not-found-btn {
      margin: 2rem 0;
      border: 1px solid $white;
      padding: 12px 24px;
      background: $color-blue;
      font-size: 16px;
      font-weight: 700;
      color: $white;
      cursor: pointer;
  
      @include transition(0.5s, ease-in-out, none, 0); 
  
      &:hover {
          background: $white;
          color: $color-blue;
          border: 1px solid $color-blue;
      }
    }
  }
  