@import './../../../../scss/partials/variables';

.top_nav {
  // position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent; 
  z-index: 99;
  border-bottom: 1px solid #BED7F8; 
}

.top_nav_link {
  height: 100%;
}

.top_contact_info {
  ul {
    padding: 0.54rem 2vmax;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    li {
      margin-right: 1rem;

      a {
        color: #6c90b9;
        margin-right: 40px;
        font-size: 14px;
      }
      i {
        margin-right: 0.5rem;
      }
    }
  }
}

.social_icon {
  ul {
    padding: 0.5rem 2vmax;
    display: flex;
    align-items: center;
    justify-content: flex-end !important;

    li {
      a {
        color: #6c90b9;
        margin-right: 20px;
        font-size: 14px;
        transition: all 0.3s ease-out 0s;

        &:hover {
            color: $color-blue-1;
        }
      }
      i {
        margin-right: 0.5rem;
      }
    }
  }
}


@media screen and (max-width: 411px) {
  .top_nav {
    display: none;
  }
}