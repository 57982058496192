@import "./scss/partials/variables";
@import "./scss/partials/mixins";

.container-fluid {
  width: 100%;
  // padding-right: 15px;
  // padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.slick-prev,
.slick-next {
  display: none !important;
}

.main-btn {
  display: inline-block;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 0;
  padding: 0 50px;
  font-size: 14px;
  line-height: 60px;
  border-radius: 0px;
  color: $white;
  cursor: pointer;
  z-index: 5;
  transition: 0.8s;
  background-color: $color-blue;
  position: relative;
  z-index: 10;
  overflow: hidden;
  letter-spacing: 1.5px;

  &::before {
    position: absolute;
    content: "";
    background-color: $color-light-blue;
    width: 100%;
    height: 0%;
    left: 50%;
    top: 50%;
    border-radius: 33px;
    @include transform(translate(-50%, -50%) rotate(-45deg));
    z-index: -1;
    -webkit-transition: all 500ms ease;
    transition: all 800ms ease;
    opacity: 1;
  }

  &:hover {
    background-color: $white;
    color: $color-blue;
    border-color: $color-blue;

    &::before {
      height: 400px;
      opacity: 0;
    }
  }
}

.border-1px {
  border: 1px solid $color-blue !important;
}

.page_container {
  position: relative;
  top: -80px;
}

// all margin class
.p-1 {
  padding: 1rem;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.text_center {
  text-align: center !important;
}

.py-100 {
  padding: 50px 0px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-200 {
  padding-top: 200px;
}

//  TEXT STYLES
.tag_title {
  color: $color-blue-1;
  font-weight: 700;
  letter-spacing: 2px;
  position: relative;
  padding-bottom: 28px;

  &::before {
    position: absolute;
    content: "";
    top: 10px;
    right: -50px;
    height: 2px;
    width: 30px;
    background: $color-blue-1;
  }
}

.description_p {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: $color-text-grey-2;
  margin: 0px;

  b {
    font-weight: bolder;
  }
}

.description_title {
  font-weight: 700;
  color: $color-text-1;
  font-size: 24px;
  padding-top: 13px;
  padding-bottom: 14px;
}

.section_title_h {
  color: $color-text-1;
  margin-bottom: 13px;
  font-size: 60px;
  font-weight: 700;
  text-transform: capitalize;
  position: relative;
  z-index: 5;
}

.w-100 {
  width: 100%;
}


@media screen and (max-width: 512px) {
  .section_title_h {
    font-size: 2rem;
  }
}


@media screen and (max-width: 338px) {
  .section_title_h {
    font-size: 1.5rem;
  }
}