.footer-copyright {
    background: #15101e;
    border-top: 1px solid #1d1729;
    color: #b0a9c0;
    padding: 30px 0;
    text-align: center;

    strong {
            color: rgb(184, 130, 252);
        }
}