@import "./../../../scss/partials/mixins";
@import "./../../../scss/partials/variables";

.header-nav {
  -webkit-box-shadow: 0 0 18px 1px #0000001a;
  box-shadow: 0 0 18px 1px #0000001a;
}

.navigation {
  position: absolute;
  right: 0;
  left: 0;
  padding-left: 30px;
  padding-right: 30px;
  z-index: 99;
}

.navbar {
  width: 100%;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;

  .navbar_logo {
    display: inline-block;
    padding-top: 0.5125rem;
    // padding-bottom: 0.3125rem;
    margin-right: 1rem;  
    // width: 100%;
    // height: 70px; 
    
      img {
        height: 70px;
        width: 140px;
      }
    
  }

  //
  .navbar-toggler {
    color: #00000080;
    border-color: #0000001a;
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;

    .toggler-icon {
      width: 30px;
      height: 2px;
      background-color: $color-text-1;
      margin: 5px 0;
      display: block;
      position: relative;
      @include transition(all, 0.3s, ease-out, 0s);
    }
  }
}
.active {
  .toggler-icon:nth-of-type(3) {
    @include transform(rotate(135deg));
    top: -7px;
  }

  .toggler-icon:nth-of-type(2) {
    opacity: 0;
  }

  .toggler-icon:nth-of-type(1) {
    @include transform(rotate(45deg));
    top: 7px;
  }
}

.collapse:not(.show) {
  @include transition(all, 0.3s, ease-out, 0s);
  display: none;
}

.navbar-nav {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  justify-content: space-between;
}

.nav-item {
  position: relative;

  a {
    font-size: 16px;
    font-weight: 700;
    padding: 0 1.4vmax;
    display: block !important;
    margin-right: 1rem;
    color: #4f79a9;
    text-transform: uppercase;
    position: relative;
    @include transition(all, 0.3s, ease-out, 0s);
    margin: 0;
    line-height: 60px;
  }

  .sub-nav-toggler {
    display: none;
    transition: visibility 0s linear 0.33s, opacity 0.33s linear;
  }

  &:hover .sub-menu {
    opacity: 1;
    visibility: visible;
    top: 100%;
  }
}

.sub-menu {
  position: absolute;
  left: 0;
  top: 110%;
  width: 270px;
  background-color: $white;
  opacity: 0;
  visibility: hidden;
  @include transition(all, 0.3s, ease-out, 0s);
  z-index: 99;
  -webkit-box-shadow: 0 2px 6px 0 #00000029;
  -moz-box-shadow: 0 2px 6px 0 #00000029;
  box-shadow: 0 2px 6px 0 #00000029;

  > li {
    position: relative;
    transition: all 0.3s ease-out 0s;
    &:hover > a {
      background-color: $color-blue-1;
      color: $white !important;
    }

    a {
      display: block;
      padding: 8px 24px;
      position: relative;
      color: #4f79a9;
      @include transition(all, 0.3s, ease-out, 0s);
      border-radius: 0;
      margin: 0 0;
      line-height: 30px;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 700;
    }
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
  }

  .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;

    .navbar-nav {
      -ms-flex-direction: row;
      flex-direction: row;
      margin: auto !important;
    }
  }
  .navbar-toggler {
    display: none;
    transition: visibility 0s linear 0.33s, opacity 0.33s linear;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-collapse {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: $white;
    z-index: 8;
    padding: 10px 16px;
    box-shadow: 0 26px 48px 0 rgb(0 0 0 / 15%);
  }
}

.navbar_sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: $white !important;
  box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 4%);
  z-index: 999;
  animation: sticky 0.4s;
}

@-webkit-keyframes sticky {
  0% {
    top: -100px;
  }
  100% {
    top: 0;
  }
}
@keyframes sticky {
  0% {
    top: -100px;
  }
  100% {
    top: 0;
  }
}


@media screen and (max-width: 668px) {
  .lets_talk_btn {
    display: none;
  }
}