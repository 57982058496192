@mixin transition($one, $two, $three, $four) {
  -webkit-transition: $one $two $three $four;
  -moz-transition: $one $two $three $four;
  -ms-transition: $one $two $three $four;
  -o-transition: $one $two $three $four;
  transition: $one $two $three $four;
}

@mixin transform($one) {
  -webkit-transform: $one;
  -moz-transform: $one;
  -ms-transform: $one;
  -o-transform: $one;
  transform: $one;
}
