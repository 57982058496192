// theme
$current-theme: dark;

// colors
$color-dark-bg: #3b3b41;
$color-light-bg: #f3f3f3;
$color-dark-border: #5250507e;

$color-light: #a2a5b9;
$color-dark: #1d2129;
$color-primary: #5f2de1;
$color-danger: #f82828;
$color-success: green;

// theme - blue
$white: #fff;
$black: #000;
$black-2: #15101e;
$black-4: #b882fc00;
// $color-blue: #5f2dee;
$color-blue: #0046be;
// $color-light-blue: #b882fc;
$color-light-blue: #7aaafd;
// $color-light-blue-1: #9f7cfd;
$color-light-blue-1: #4387fd;
$color-light-black: #6c6a72;
$color-light-black-2: #a4a2a9;
$color-light-gray: #b0a9c0;
$color-dark-black: #1d1729;
$color-white-2: #f4f2f9;
$color-white-3: #f8f2ff;
$color-white-4: #e1d6ff;
$color-white-6: #e4e0ee;

$color-shadow-black: #0000000d;
// theme - white

// theme - purple
$color-purple: #800080;
$color-light-purple: #ba68c8;
$purple-dark-light: #6a0dad;
$color-dark-purple: #4a148c;
$color-deep-purple: #311b92;
$purple-dark: #301934;
// theme - red
$color-dark-orange: #f82828;

// font
$font-base-size: 14px;
$font-base-family: Helvetica;

// TEXT COLOR
$color-text-1: #002249;
$color-border-grey: #e3eeff;
$color-text-grey-1: #69a1bb;
$color-text-grey-2: #81a3bb;
$color-blue-1: #0c59db;
